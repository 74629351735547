import "../../styles/shared/Header.scss";
import Logo from "../../images/closecarbon_logo_1-removebg-preview 1.png";
import { useLocation, useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState } from "react";
import ContactUs from "./ContactUs";

export default function Header() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [navActive, setNavActive] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [demosent, setDemoSent] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const handleModalOPen = () => {
    setModalOpen(true);
    setNavActive(false);
  };

  useEffect(() => {
    const temp = localStorage.getItem("demosent");
    if(temp === "true") {
      setDemoSent(true);
    }
  }, [])

  useEffect(() => {
console.log('pathname', pathname);
  }, [])

  return (
    <>
      <header>
        <div className="header_inner">
          <div className="logo">
            <img src={Logo} alt="" />
          </div>
          <div className="menu">
            <div
              className={pathname === "/" && "active"}
              onClick={() => {
                navigate("/");
                window.scrollTo(0, 0);
              }}
            >
              Home {pathname === "/" && <div className="active_bar"></div>}
            </div>
            <div onClick={handleClick2} onMouseOver={handleClick2}
            className={pathname.includes("plans") || anchorEl2 ? "active" : ""}
            >
            Plans{" "}
              <svg
                width="14"
                height="9"
                viewBox="0 0 14 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.5 1L7 7L0.5 1"
                  stroke="#898989"
                  stroke-width="1.5"
                />
              </svg>
            </div>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl2}
              open={open2}
              onClose={handleClose2}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                sx={{
                  width: "150px",
                  fontSize: "14px",
                  fontFamily: "Inter",
                  fontWeight: "500",
                  "&:hover": {
                    backgroundColor: "rgba(0, 102, 51, 0.29)",
                  },
                }}
                className="subMenu"
                onClick={() => {
                  navigate("/plans/basic");
                  window.scrollTo(0, 0);
                }}
              >
                Basic
              </MenuItem>
              <MenuItem
                sx={{
                  width: "150px",
                  fontSize: "14px",
                  fontFamily: "Inter",
                  fontWeight: "500",
                  "&:hover": {
                    backgroundColor: "rgba(0, 102, 51, 0.29)",
                  },
                }}
                className="subMenu"
                onClick={() => {
                  navigate("/plans/governance");
                  window.scrollTo(0, 0);
                }}
              >
                Governance
              </MenuItem>
              <MenuItem
                sx={{
                  width: "150px",
                  fontSize: "14px",
                  fontFamily: "Inter",
                  fontWeight: "500",
                  "&:hover": {
                    backgroundColor: "rgba(0, 102, 51, 0.29)",
                  },
                }}
                className="subMenu"
                onClick={() => {
                  navigate("/plans/verifier");
                  window.scrollTo(0, 0);
                }}
              >
                Verifiers
              </MenuItem>
            </Menu>
            <div
              className={pathname === "/solutions" ? "mid active" : "mid"}
              onClick={() => {
                navigate("/solutions");
                window.scrollTo(0, 0);
              }}
            >
              Solutions{" "}
              {pathname === "/solutions" && <div className="active_bar"></div>}
            </div>
            <div onClick={handleClick} onMouseOver={handleClick}
            className={pathname.includes("blogs") || pathname.includes("about") || pathname.includes("resources") || anchorEl ? "active" : ""}
            >
              Company{" "}
              <svg
                width="14"
                height="9"
                viewBox="0 0 14 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.5 1L7 7L0.5 1"
                  stroke="#898989"
                  stroke-width="1.5"
                />
              </svg>
            </div>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                sx={{
                  width: "150px",
                  fontSize: "14px",
                  fontFamily: "Inter",
                  fontWeight: "500",
                  "&:hover": {
                    backgroundColor: "rgba(0, 102, 51, 0.29)",
                  },
                }}
                className="subMenu"
                onClick={() => {
                  navigate("/about");
                  window.scrollTo(0, 0);
                }}
              >
                About us
              </MenuItem>
              <MenuItem
                sx={{
                  width: "150px",
                  fontSize: "14px",
                  fontFamily: "Inter",
                  fontWeight: "500",
                  "&:hover": {
                    backgroundColor: "rgba(0, 102, 51, 0.29)",
                  },
                }}
                className="subMenu"
                onClick={() => {
                  navigate("/blogs");
                  window.scrollTo(0, 0);
                }}
              >
                Blog
              </MenuItem>
              <MenuItem
                sx={{
                  width: "150px",
                  fontSize: "14px",
                  fontFamily: "Inter",
                  fontWeight: "500",
                  "&:hover": {
                    backgroundColor: "rgba(0, 102, 51, 0.29)",
                  },
                }}
                className="subMenu"
                onClick={() => {
                  navigate("/resources");
                  window.scrollTo(0, 0);
                }}
              >
                Resources
              </MenuItem>
            </Menu>
          </div>
          <div className="contact">
            <button className="header_login" onClick={() => navigate("/login")}>
              Login
            </button>
            
            {/* {!demosent &&  */}
            <button className="header_contact" onClick={handleModalOPen}>
              Contact us{" "}
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.4994 16.9201V19.9201C22.5006 20.1986 22.4435 20.4743 22.332 20.7294C22.2204 20.9846 22.0567 21.2137 21.8515 21.402C21.6463 21.5902 21.404 21.7336 21.1402 21.8228C20.8764 21.912 20.5968 21.9452 20.3194 21.9201C17.2423 21.5857 14.2864 20.5342 11.6894 18.8501C9.27327 17.3148 7.22478 15.2663 5.68945 12.8501C3.99942 10.2413 2.94769 7.27109 2.61944 4.1801C2.59446 3.90356 2.62732 3.62486 2.71595 3.36172C2.80457 3.09859 2.94702 2.85679 3.13421 2.65172C3.32141 2.44665 3.54925 2.28281 3.80324 2.17062C4.05722 2.05843 4.33179 2.00036 4.60945 2.0001H7.60945C8.09475 1.99532 8.56524 2.16718 8.93321 2.48363C9.30118 2.80008 9.54152 3.23954 9.60944 3.7201C9.73607 4.68016 9.97089 5.62282 10.3094 6.5301C10.444 6.88802 10.4731 7.27701 10.3934 7.65098C10.3136 8.02494 10.1283 8.36821 9.85944 8.6401L8.58945 9.9101C10.013 12.4136 12.0859 14.4865 14.5894 15.9101L15.8594 14.6401C16.1313 14.3712 16.4746 14.1859 16.8486 14.1062C17.2225 14.0264 17.6115 14.0556 17.9694 14.1901C18.8767 14.5286 19.8194 14.7635 20.7794 14.8901C21.2652 14.9586 21.7088 15.2033 22.026 15.5776C22.3431 15.9519 22.5116 16.4297 22.4994 16.9201Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            {/* } */}
           {/* {demosent && <button className="header_contact" onClick={() => navigate("/signup")}>
              Sign Up
              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.0459 12.7959L10.2959 16.5459C10.0846 16.7573 9.79795 16.876 9.49906 16.876C9.20018 16.876 8.91353 16.7573 8.70219 16.5459C8.49084 16.3346 8.37211 16.0479 8.37211 15.7491C8.37211 15.4502 8.49084 15.1635 8.70219 14.9522L10.5312 13.125H2.75C2.45163 13.125 2.16548 13.0065 1.9545 12.7955C1.74353 12.5845 1.625 12.2984 1.625 12C1.625 11.7016 1.74353 11.4155 1.9545 11.2045C2.16548 10.9935 2.45163 10.875 2.75 10.875H10.5312L8.70406 9.04594C8.59942 8.94129 8.5164 8.81706 8.45977 8.68033C8.40314 8.5436 8.37399 8.39706 8.37399 8.24906C8.37399 7.95018 8.49272 7.66353 8.70406 7.45219C8.91541 7.24084 9.20205 7.12211 9.50094 7.12211C9.79982 7.12211 10.0865 7.24084 10.2978 7.45219L14.0478 11.2022C14.1526 11.3068 14.2356 11.4311 14.2923 11.5679C14.3489 11.7047 14.3779 11.8514 14.3778 11.9995C14.3776 12.1475 14.3482 12.2941 14.2912 12.4308C14.2343 12.5675 14.1509 12.6915 14.0459 12.7959ZM18.5 2.625H13.25C12.9516 2.625 12.6655 2.74353 12.4545 2.9545C12.2435 3.16548 12.125 3.45163 12.125 3.75C12.125 4.04837 12.2435 4.33452 12.4545 4.5455C12.6655 4.75647 12.9516 4.875 13.25 4.875H18.125V19.125H13.25C12.9516 19.125 12.6655 19.2435 12.4545 19.4545C12.2435 19.6655 12.125 19.9516 12.125 20.25C12.125 20.5484 12.2435 20.8345 12.4545 21.0455C12.6655 21.2565 12.9516 21.375 13.25 21.375H18.5C18.9973 21.375 19.4742 21.1775 19.8258 20.8258C20.1775 20.4742 20.375 19.9973 20.375 19.5V4.5C20.375 4.00272 20.1775 3.52581 19.8258 3.17417C19.4742 2.82254 18.9973 2.625 18.5 2.625Z" fill="white"/>
</svg>

            </button>} */}
          </div>
          <svg
            className="hamburger"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => setNavActive(true)}
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M21.0625 7.5C21.0625 7.69891 20.9835 7.88968 20.8428 8.03033C20.7022 8.17098 20.5114 8.25 20.3125 8.25H4.3125C4.11359 8.25 3.92282 8.17098 3.78217 8.03033C3.64152 7.88968 3.5625 7.69891 3.5625 7.5C3.5625 7.30109 3.64152 7.11032 3.78217 6.96967C3.92282 6.82902 4.11359 6.75 4.3125 6.75H20.3125C20.5114 6.75 20.7022 6.82902 20.8428 6.96967C20.9835 7.11032 21.0625 7.30109 21.0625 7.5ZM21.0625 12.5C21.0625 12.6989 20.9835 12.8897 20.8428 13.0303C20.7022 13.171 20.5114 13.25 20.3125 13.25H4.3125C4.11359 13.25 3.92282 13.171 3.78217 13.0303C3.64152 12.8897 3.5625 12.6989 3.5625 12.5C3.5625 12.3011 3.64152 12.1103 3.78217 11.9697C3.92282 11.829 4.11359 11.75 4.3125 11.75H20.3125C20.5114 11.75 20.7022 11.829 20.8428 11.9697C20.9835 12.1103 21.0625 12.3011 21.0625 12.5ZM21.0625 17.5C21.0625 17.6989 20.9835 17.8897 20.8428 18.0303C20.7022 18.171 20.5114 18.25 20.3125 18.25H4.3125C4.11359 18.25 3.92282 18.171 3.78217 18.0303C3.64152 17.8897 3.5625 17.6989 3.5625 17.5C3.5625 17.3011 3.64152 17.1103 3.78217 16.9697C3.92282 16.829 4.11359 16.75 4.3125 16.75H20.3125C20.5114 16.75 20.7022 16.829 20.8428 16.9697C20.9835 17.1103 21.0625 17.3011 21.0625 17.5Z"
              fill="#B1B1B1"
            />
          </svg>
        </div>
        <div className={navActive ? "mobileNav mobileNav_active" : "mobileNav"}>
          <div className="mobileNav_svg_container">
            <svg
              onClick={() => setNavActive(false)}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z"
                fill="#141414"
              />
            </svg>
          </div>

          <div
            onClick={() => {
              navigate("/");
              window.scrollTo(0, 0);
            }}
          >
            Home
          </div>
          <div
            onClick={() => {
              navigate("/plans/basic");
              window.scrollTo(0, 0);
            }}
          >
            Basic
           </div>
          <div
            onClick={() => {
              navigate("/plans/governance");
              window.scrollTo(0, 0);
            }}
          >
            Governance
           </div>
          <div
            onClick={() => {
              navigate("/plans/verifier");
              window.scrollTo(0, 0);
            }}
          >
            Verifiers
           </div>
          <div
            onClick={() => {
              navigate("/solutions");
              window.scrollTo(0, 0);
            }}
          >
            Solutions
          </div>
          <div
            onClick={() => {
              navigate("/about");
              window.scrollTo(0, 0);
            }}
          >
            About us
          </div>
          <div
            onClick={() => {
              navigate("/blogs");
              window.scrollTo(0, 0);
            }}
          >
            Blog
          </div>
          <div
            onClick={() => {
              navigate("/resources");
              window.scrollTo(0, 0);
            }}
          >
            Resources
          </div>
          <div
            onClick={() => {
              navigate("/login");
              window.scrollTo(0, 0);
            }}
          >
            Login
          </div>
          <button onClick={handleModalOPen}>
            Contact us{" "}
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.4994 16.9201V19.9201C22.5006 20.1986 22.4435 20.4743 22.332 20.7294C22.2204 20.9846 22.0567 21.2137 21.8515 21.402C21.6463 21.5902 21.404 21.7336 21.1402 21.8228C20.8764 21.912 20.5968 21.9452 20.3194 21.9201C17.2423 21.5857 14.2864 20.5342 11.6894 18.8501C9.27327 17.3148 7.22478 15.2663 5.68945 12.8501C3.99942 10.2413 2.94769 7.27109 2.61944 4.1801C2.59446 3.90356 2.62732 3.62486 2.71595 3.36172C2.80457 3.09859 2.94702 2.85679 3.13421 2.65172C3.32141 2.44665 3.54925 2.28281 3.80324 2.17062C4.05722 2.05843 4.33179 2.00036 4.60945 2.0001H7.60945C8.09475 1.99532 8.56524 2.16718 8.93321 2.48363C9.30118 2.80008 9.54152 3.23954 9.60944 3.7201C9.73607 4.68016 9.97089 5.62282 10.3094 6.5301C10.444 6.88802 10.4731 7.27701 10.3934 7.65098C10.3136 8.02494 10.1283 8.36821 9.85944 8.6401L8.58945 9.9101C10.013 12.4136 12.0859 14.4865 14.5894 15.9101L15.8594 14.6401C16.1313 14.3712 16.4746 14.1859 16.8486 14.1062C17.2225 14.0264 17.6115 14.0556 17.9694 14.1901C18.8767 14.5286 19.8194 14.7635 20.7794 14.8901C21.2652 14.9586 21.7088 15.2033 22.026 15.5776C22.3431 15.9519 22.5116 16.4297 22.4994 16.9201Z"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
      </header>
      <div className="headerDivider"></div>
      <ContactUs
        handleModalOPen={handleModalOPen}
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
      />
    </>
  );
}
