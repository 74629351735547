import { useEffect, useState } from "react";
import "../../styles/ThirdParty/Validation.scss";
import {
  getPlantsData as greenBasicGetAllEmissionData,
  getStaticAttributes as greenBasicGetStaticAttributes,
} from "../../instantiateTransaction/basicGreenProject";
import {
  getPlantsData as infraBasicGetAllEmissionData,
  getStaticAttributes as infraBasicGetStaticAttributes,
} from "../../instantiateTransaction/basicInfrastructureChange";
import {
  getPlantsData as greenGovernanceGetAllEmissionData,
  getStaticAttributes as greenGovernanceGetStaticAttributes,
} from "../../instantiateTransaction/governanceGreenProject";

import {
  getPlantsData as governanceInfraGetAllEmissionData,
  getStaticAttributes as governanceInfraGetStaticAttributes,
} from "../../instantiateTransaction/gorvernanceInfrastructureChange";

import {
  getPlantsData as governanceMonitoringGetAllEmissionData,
  getStaticAttributes as governanceMonitoringGetStaticAttributes,
} from "../../instantiateTransaction/governanceCarbonMonitoring";

import { toast } from "react-toastify";
import { ethers } from "ethers";
import { getDatabase, ref, onValue, push } from "firebase/database";

import moment from "moment";
import {
  facilityReject,
  facilityValidate,
} from "../../apis/General/web3/shared";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Illustration from "../../images/9264828.jpg";
import ReactQuill from "react-quill";
import { getKyc } from "../../apis/General/users";
import { useNavigate } from "react-router-dom";
import Kyc from "../../components/ThirdPartyDashboard/Kyc";
import SideBar from "../../components/AdminDashboard/SideBar";
import { Tab, Tabs } from "@mui/material";
import Search from "../../components/AdminDashboard/Search";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Validation = () => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  console.log("auth", auth);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [validateLoading, setValidateLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [facilityLoading, setFacilityLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [content, setContent] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [quantifiedBaseYearInventory, setQuantifiedBaseYearInventory] =
    useState("");
  const [quantificationMethodology, setQuantificationMethodology] =
    useState("");
  const [selectedStaticAttributes, setSelectedStaticAttributes] =
    useState(null);
  const [selectedId, setSelectedId] = useState("");
  const [selectedKyc, setSelectedKyc] = useState("");
  const [reason, setReason] = useState("");
  const [value, setValue] = useState(0);
  const [facilityType, setFacilityType] = useState("green");
  const [greenBasicFacilitiesValidated, setGreenBasicFacilitiesValidated] =
    useState([]);
  const [greenBasicFacilitiesUnvalidated, setGreenBasicFacilitiesUnvalidated] =
    useState([]);
  const [infraBasicFacilitiesValidated, setInfraBasicFacilitiesValidated] =
    useState([]);
  const [infraBasicFacilitiesUnvalidated, setInfraBasicFacilitiesUnvalidated] =
    useState([]);
  const [
    greenGovernanceFacilitiesValidated,
    setGreenGovernanceFacilitiesValidated,
  ] = useState([]);
  const [
    greenGovernanceFacilitiesUnvalidated,
    setGreenGovernanceFacilitiesUnvalidated,
  ] = useState([]);
  const [
    infraGovernanceFacilitiesValidated,
    setInfraGovernanceFacilitiesValidated,
  ] = useState([]);
  const [
    infraGovernanceFacilitiesUnvalidated,
    setInfraGovernanceFacilitiesUnvalidated,
  ] = useState([]);
  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState("");

  const [monitoringValidated, setMonitoringValidated] = useState([]);
  const [monitoringUnvalidated, setMonitoringUnvalidated] = useState([]);
  const [validatedLength, setValidatedLength] = useState(0);
  const [unValidatedLength, setUnValidatedLength] = useState(0);
  const db = getDatabase();

  const handleTabChange = (event, newValue) => {
    console.log("newValue", newValue);
    setValue(newValue);
  };

  // useEffect(() => {
  //   if(auth?.userInfo?.role === "verifiers") {
  //     navigate("/third-party/verification")
  //   }
  // }, [auth])

  const handleModalOpen = async (facility, index, subscriptionPlan) => {
    setCurrentIndex(index);
    setLoading(true);
    setSelectedSubscriptionPlan(subscriptionPlan)
    try {
      console.log("facility", facility, "subscriptionPlan", subscriptionPlan, facility?.scenario, facility?.scenario === "infrastructure-change");
      setSelectedFacility(facility);
      let response;
      if (
        facility?.scenario === "infrastructure-change"
         &&
        subscriptionPlan === "basic"
      ) {
        response = await infraBasicGetStaticAttributes(facility?.plantAddress);
      } else if (
        facility?.scenario === "green"
        &&
        subscriptionPlan === "basic"
      ) {
        response = await greenBasicGetStaticAttributes(facility?.plantAddress);
      }else if (
        facility?.scenario === "infrastructure-change" 
        &&
        subscriptionPlan === "governance"
      ) {
        response = await governanceInfraGetAllEmissionData(facility?.plantAddress);
      } else if (
        facility?.scenario === "green" 
        &&
        subscriptionPlan === "governance"
      ) {
        response = await greenGovernanceGetStaticAttributes(facility?.plantAddress);
      }else if (facility?.scenario === "carbon-monitoring") {
        response = await governanceMonitoringGetStaticAttributes(
          facility?.plantAddress
        );
      } else {
        alert("Invalid Scenario");
      }
      console.log("response static", response);
      const response2 = await getKyc(
        facility?.scenario === "green" && subscriptionPlan === "basic"
          ? response?.staticAttributes[5] :
          facility?.scenario === "infrastructure-change" && subscriptionPlan === "basic"
          ? response?.staticAttributes[6] :
          facility?.scenario === "green" && subscriptionPlan === "governance"
          ? response?.staticAttributes[7] :
          facility?.scenario === "infrastructure-change" && subscriptionPlan === "governance"
          ? response?.staticAttributes[7] :
          response?.staticAttributes[7],
        auth?.token
      );
      console.log("response2", response2);
      setSelectedStaticAttributes(response?.staticAttributes);
      setSelectedKyc(response2?.data?.message);
      setSelectedId(
        facility?.scenario === "green" && subscriptionPlan === "basic"
          ? response?.staticAttributes[5] :
          facility?.scenario === "infrastructure-change" && subscriptionPlan === "basic"
          ? response?.staticAttributes[6] :
          facility?.scenario === "green" && subscriptionPlan === "governance"
          ? response?.staticAttributes[7] :
          facility?.scenario === "infrastructure-change" && subscriptionPlan === "governance"
          ? response?.staticAttributes[7] :
          response?.staticAttributes[7]
      );
      setModalOpen(true);
    } catch (error) {
      console.log("ERROR", error);
      toast.error("Something went wrong");
    }
    setFacilityLoading(false);
  };

  const handleModalClose = (e) => {
    if (e.target.classList.contains("modal")) {
      setModalOpen(false);
    }
  };

  const handleModalClose2 = (e) => {
    if (e.target.classList.contains("reject-modals")) {
      setModalOpen2(false);
    }
  };

  const getValidationData = async () => {
    setLoading(true);
    // console.log('wemba')

    try {
      const response6 = await greenBasicGetAllEmissionData(0, 20);
      console.log("response6", response6);

      const temp1 = response6?.getAllInfo?.filter((item) => !!item?.isValidated);
      const temp2 = response6?.getAllInfo?.filter((item) => !item?.isValidated);
      setGreenBasicFacilitiesValidated(temp1);
      setGreenBasicFacilitiesUnvalidated(temp2);

      const response7 = await infraBasicGetAllEmissionData(0, 20);
      console.log("response7", response7);

      const temp3 = response7?.getAllInfo?.filter((item) => !!item?.isValidated);
      const temp4 = response7?.getAllInfo?.filter((item) => !item?.isValidated);

      setInfraBasicFacilitiesValidated(temp3);
      setInfraBasicFacilitiesUnvalidated(temp4);

      const response8 = await greenGovernanceGetAllEmissionData(0, 20);
      console.log("response8", response8);

      const temp5 = response8?.getAllInfo?.filter((item) => !!item?.isValidated);
      const temp6 = response8?.getAllInfo?.filter((item) => !item?.isValidated);

      setGreenGovernanceFacilitiesValidated(temp5);
      setGreenGovernanceFacilitiesUnvalidated(temp6);

      const response9 = await governanceInfraGetAllEmissionData(0, 20);
      console.log("response9", response9);

      const temp7 = response9?.getAllInfo?.filter((item) => !!item?.isValidated);
      const temp8 = response9?.getAllInfo?.filter((item) => !item?.isValidated);

      setInfraGovernanceFacilitiesValidated(temp7);
      setInfraGovernanceFacilitiesUnvalidated(temp8);

      const response10 = await governanceMonitoringGetAllEmissionData(0, 20);
      console.log('response10', response10);

      const temp9 = response10?.getAllInfo?.filter(
        (item) => !!item?.isValidated
      );
      const temp10 = response10?.getAllInfo?.filter(
        (item) => !item?.isValidated
      );

      setMonitoringValidated(temp9);
      setMonitoringUnvalidated(temp10);

      setValidatedLength(
        temp1?.length +
          temp3?.length +
          temp5?.length +
          temp7?.length +
          temp9?.length
      );

      setUnValidatedLength(
        temp2?.length +
          temp4?.length +
          temp6?.length +
          temp8?.length +
          temp10?.length
      );
    } catch (error) {
      console.log("ERROR FROM FRONTEND", error);
      // toast.error("Something went wrong");
    }
    setLoading(false);
    // console.log("response8", response8);
    // console.log('end of the road');
  };

  const getActivities = async () => {
    // console.log('bank');
    // setLoading(true);
    const database = getDatabase();

    const newRef = ref(database, "events");
    await onValue(newRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setActivities(Object.values(data));
      }
      // setLoading(false);
    });
  };

  useEffect(() => {
    // setLoading(true)
    getValidationData();
    getActivities();
    // setLoading(false)
  }, []);

  const getLastActivity = (address) => {
    const filteredData = activities?.filter(
      (item) =>
        item?.addressToRecord === address && item?.type === "Plant Validated"
    );
    const lastData = filteredData[filteredData.length - 1];
    if (filteredData?.length > 0) {
      return `${lastData?.type}, by ${lastData?.addressToRecord} on ${moment(
        parseInt(lastData?.timestamp) * 1000
      ).format("Do MMM YYYY")} at ${moment(
        parseInt(lastData?.timestamp) * 1000
      ).format("hh:mm A")}`;
    } else {
      return "No Validation has been carried out for this facility's project";
    }
  };

  // console.log("activities2", activities);

  const handleValidate = async (facility) => {
    setValidateLoading(true);
    let response;
    try {
      if (facility?.isValidated) {
        alert(
          "Validation has already been carried out for this Facility's data"
        );
        setModalOpen(false);
        return;
      } 
      // else if (facility?.isVerified) {
      //   alert(
      //     "Verification has already been carried out for this Facility's data"
      //   );
      //   setModalOpen(false);
      // } 
      else if (facility?.isRejected) {
        alert("This Facility's data failed Validation or Verification");
        setModalOpen(false);
      } 
      else if (
        facility?.scenario === "infrastructure-change" && selectedSubscriptionPlan === "basic"
      ) {
        response = await facilityValidate(
          selectedId,
          facility?.plantAddress,
          "infrastructure-change",
          "basic",
          auth?.token
        );
      }
       else if (
        facility?.scenario === "green" && selectedSubscriptionPlan === "basic"
      ) {
        response = await facilityValidate(
          selectedId,
          facility?.plantAddress,
          "green",
          "basic",
          auth?.token
        );
      } else if (facility?.scenario === "infrastructure-change" && selectedSubscriptionPlan === "governance"
        ) {
        response = await facilityValidate(
          selectedId,
          facility?.plantAddress,
          "infrastructure-change",
          "governance",
          auth?.token
        );
      } 
      else if (facility?.scenario === "green" && selectedSubscriptionPlan === "governance"
      ) {
        console.log("selectedKyc?.subscriptionPlan", selectedKyc, selectedKyc?.subscriptionPlan);
        response = await facilityValidate(
              selectedId,
              facility?.plantAddress,
              "green",
              "governance",
              auth?.token
            );
      }
      else if (facility?.scenario === "carbon-monitoring") {
        response = await facilityValidate(
          selectedId,
          facility?.plantAddress,
          "carbon-monitoring",
          "governance",
          auth?.token
        );
      } 
      else {
        alert("Invalid Scenario");
        setLoading(false)
        return;
      }
      console.log("repsonse", response);
      if (response?.data?.statusCode === 200) {
        toast.success("Validation Successful");
        setModalOpen(false);
        push(ref(db, "notifications"), {
          type: "Validation successful",
          read: false,
          user: selectedId,
          reason,
          timestamp: Date.now(),
        });
        getValidationData();
      } else if (response?.data?.errorMessage) {
        toast.error(response?.data?.errorMessage);
      } else if (response?.data?.message) {
        toast.error(response?.data?.message);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.log("ERROR", error);
      toast.error("Something went wrong");
    }
    setValidateLoading(false);
  };

  const handleReject = async (facility) => {
    console.log("facility", facility);
    setRejectLoading(true);
    try {
      if (facility?.isValidated) {
        alert(
          "Validation has already been carried out for this Facility's data"
        );
        setModalOpen(false);
      } else if (facility?.isVerified) {
        alert(
          "Verification has already been carried out for this Facility's data"
        );
        setModalOpen(false);
      } else if (facility?.isRejected) {
        alert("This Facility's data has already been rejected");
        setModalOpen(false);
      } else if (reason === "") {
        alert("Please select a reason for rejection");
      } else {
        console.log("deyyah", selectedId);
        const response = await facilityReject(reason, selectedId, auth?.token);
        console.log("barnies", response);
        if (response?.data?.statusCode === 200) {
          toast.success("Validation Rejected");
          setModalOpen(false);
          setModalOpen2(false);
          push(ref(db, "notifications"), {
            type: "Validation rejected",
            read: false,
            user: selectedId,
            reason,
            timestamp: Date.now(),
          });
        }
      }
    } catch (error) {
      console.log("ERROR", error);
      toast.error("Something went wrong");
    }
    setRejectLoading(false);
  };

  // };

  const quilModules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
    ],
  };

  const quilFormats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
  ];


  return (
    <div className="thirdparty_validation">
      <div className="sidebar_container">
        <SideBar />
      </div>

      <div className="main">
        <Search />
        <Tabs
          value={value}
          onChange={handleTabChange}
          aria-label="project tabs"
          className="tab_label_container"
        >
          <Tab
            className={value === 0 ? "tab_label tab_active" : "tab_label"}
            label={
              <div className="tab-label-content">
                <span>Unvalidated Projects</span>
                {unValidatedLength > 0 && (
                  <span className="tab-label-content__count">
                    {unValidatedLength}
                  </span>
                )}
              </div>
            }
            {...a11yProps(0)}
          />
          <Tab
            className={value === 1 ? "tab_label tab_active" : "tab_label"}
            label={
              <div className="tab-label-content">
                <span>Validated Projects</span>
                {validatedLength > 0 && (
                  <span className="tab-label-content__count">
                    {validatedLength}
                  </span>
                )}
              </div>
            }
            {...a11yProps(1)}
          />
        </Tabs>
        <div className="validation-table">
          <div className="validation-table__header">
            <div>
              <button
                className={facilityType === "green" && "active"}
                onClick={() => setFacilityType("green")}
              >
                Green
              </button>
              {!facilityType && <div></div>}
              <button
                className={facilityType === "non-green" && "active"}
                onClick={() => setFacilityType("non-green")}
              >
                Non-green
              </button>
              {!facilityType && <div></div>}
            </div>
            {/* <div>
              <button
                className={!facilityType && "active"}
                onClick={() => setFacilityType(null)}
              >
                View all
              </button>
            </div> */}
          </div>

          {!loading &&
            value === 0 &&
            greenBasicFacilitiesUnvalidated?.length === 0 &&
            infraBasicFacilitiesUnvalidated?.length === 0 &&
            greenGovernanceFacilitiesUnvalidated?.length === 0 &&
            infraGovernanceFacilitiesUnvalidated?.length === 0 &&
            monitoringUnvalidated?.length === 0 && (
              <div className="empty_list">
                <img src={Illustration} alt="" />
                <div>No validation data here yet</div>
              </div>
            )}

          {!loading &&
            value === 1 &&
            greenBasicFacilitiesValidated?.length === 0 &&
            infraBasicFacilitiesValidated?.length === 0 &&
            greenGovernanceFacilitiesValidated?.length === 0 &&
            infraGovernanceFacilitiesValidated?.length === 0 &&
            monitoringValidated?.length === 0 && (
              <div className="empty_list">
                <img src={Illustration} alt="" />
                <div>No validation data here yet</div>
              </div>
            )}
          {/* {greenFacilities?.length > 0 && (
          <div className="category">Green Project</div>
        )} */}
          {value === 1 &&
            facilityType === "green" &&
            greenBasicFacilitiesValidated?.map((facility, index) => (
              <div className="validation_card">
                <div className="validation_grid">
                  <div>
                    <div>Facility Name:</div>
                    <div>
                      {facility?.organizationName && facility?.organizationName}
                    </div>
                  </div>
                  {/* <div>
              <div>Methodology:</div>
              <div>CloseCarbon</div>
            </div> */}
                  <div>
                    <div>Facility category:</div>
                    <div>Green</div>
                  </div>
                  <div>
                    <div>Sustainability solution:</div>
                    <div>Green project</div>
                  </div>
                </div>

                <div className="confirm">
                  <div>Validate this facility</div>

                  <div>
                    {/* <button
                      onClick={() =>
                        alert(
                          "You have to carry out a site visit in order to proceed with Validation"
                        )
                      }
                      disabled={facilityLoading}
                    >
                      Cancel
                    </button> */}
                    <div></div>
                    <button
                      onClick={() => handleModalOpen(facility, index, "basic")}
                      disabled={facilityLoading}
                    >
                      {facilityLoading && index === currentIndex
                        ? "Loading..."
                        : "View details"}
                    </button>
                  </div>
                </div>
                {/* <div className="log">
              <span>Last Activity:</span>{" "}
              {getLastActivity(facility?.plantAddress)}
            </div> */}
              </div>
            ))}
          {value === 0 &&
            facilityType === "green" &&
            greenBasicFacilitiesUnvalidated?.map((facility, index) => (
              <div className="validation_card">
                <div className="validation_grid">
                  <div>
                    <div>Facility Name:</div>
                    <div>
                      {facility?.organizationName && facility?.organizationName}
                    </div>
                  </div>
                  {/* <div>
              <div>Methodology:</div>
              <div>CloseCarbon</div>
            </div> */}
                  <div>
                    <div>Facility category:</div>
                    <div>Green</div>
                  </div>
                  <div>
                    <div>Sustainability solution:</div>
                    <div>Green project</div>
                  </div>
                </div>

                <div className="confirm">
                  <div>Validate this facility</div>

                  <div>
                    {/* <button
                      onClick={() =>
                        alert(
                          "You have to carry out a site visit in order to proceed with Validation"
                        )
                      }
                      disabled={facilityLoading}
                    >
                      Cancel
                    </button> */}
                    <div></div>
                    <button
                      onClick={() => handleModalOpen(facility, index, "basic")}
                      disabled={facilityLoading}
                    >
                      {facilityLoading && index === currentIndex
                        ? "Loading..."
                        : "View details"}
                    </button>
                  </div>
                </div>
                {/* <div className="log">
              <span>Last Activity:</span>{" "}
              {getLastActivity(facility?.plantAddress)}
            </div> */}
              </div>
            ))}
          {value === 1 &&
            facilityType === "green" &&
            greenGovernanceFacilitiesValidated?.map((facility, index) => (
              <div className="validation_card">
                <div className="validation_grid">
                  <div>
                    <div>Facility Name:</div>
                    <div>
                      {facility?.organizationName && facility?.organizationName}
                    </div>
                  </div>
                  {/* <div>
              <div>Methodology:</div>
              <div>CloseCarbon</div>
            </div> */}
                  <div>
                    <div>Facility category:</div>
                    <div>Green</div>
                  </div>
                  <div>
                    <div>Sustainability solution:</div>
                    <div>Green project</div>
                  </div>
                </div>

                <div className="confirm">
                  <div>Validate this facility</div>

                  <div>
                    {/* <button
                      onClick={() =>
                        alert(
                          "You have to carry out a site visit in order to proceed with Validation"
                        )
                      }
                      disabled={facilityLoading}
                    >
                      Cancel
                    </button> */}
                    <div></div>
                    <button
                      onClick={() => handleModalOpen(facility, index, "governance")}
                      disabled={facilityLoading}
                    >
                      {facilityLoading && index === currentIndex
                        ? "Loading..."
                        : "View details"}
                    </button>
                  </div>
                </div>
                {/* <div className="log">
              <span>Last Activity:</span>{" "}
              {getLastActivity(facility?.plantAddress)}
            </div> */}
              </div>
            ))}
          {value === 0 &&
            facilityType === "green" &&
            greenGovernanceFacilitiesUnvalidated?.map((facility, index) => (
              <div className="validation_card">
                <div className="validation_grid">
                  <div>
                    <div>Facility Name:</div>
                    <div>
                      {facility?.organizationName && facility?.organizationName}
                    </div>
                  </div>
                  {/* <div>
              <div>Methodology:</div>
              <div>CloseCarbon</div>
            </div> */}
                  <div>
                    <div>Facility category:</div>
                    <div>Green</div>
                  </div>
                  <div>
                    <div>Sustainability solution:</div>
                    <div>Green project</div>
                  </div>
                </div>

                <div className="confirm">
                  <div>Validate this facility</div>

                  <div>
                    {/* <button
                      onClick={() =>
                        alert(
                          "You have to carry out a site visit in order to proceed with Validation"
                        )
                      }
                      disabled={facilityLoading}
                    >
                      Cancel
                    </button> */}
                    <div></div>
                    <button
                      onClick={() => handleModalOpen(facility, index, "governance")}
                      disabled={facilityLoading}
                    >
                      {facilityLoading && index === currentIndex
                        ? "Loading..."
                        : "View details"}
                    </button>
                  </div>
                </div>
                {/* <div className="log">
              <span>Last Activity:</span>{" "}
              {getLastActivity(facility?.plantAddress)}
            </div> */}
              </div>
            ))}
          {/* {infrastructureChange?.length > 0 && (
          <div className="category">Infrastructure Change</div>
        )} */}

          {value === 1 &&
            facilityType === "non-green" &&
            infraBasicFacilitiesValidated?.map((facility, index) => (
              <div className="validation_card">
                <div className="validation_grid">
                  <div>
                    <div>Facility Name:</div>
                    <div>
                      {facility?.organizationName && facility?.organizationName}
                    </div>
                  </div>
                  {/* <div>
            <div>Methodology:</div>
            <div>CloseCarbon</div>
          </div> */}
                  <div>
                    <div>Facility category:</div>
                    <div>Non-green</div>
                  </div>
                  <div>
                    <div>Sustainability solution:</div>
                    <div>Infrastructure change</div>
                  </div>
                </div>

                <div className="confirm">
                  <div>Validate this facility</div>

                  <div>
                    {/* <button
                      onClick={() =>
                        alert(
                          "You have to carry out a site visit in order to proceed with Validation"
                        )
                      }
                      disabled={facilityLoading}
                    >
                      Cancel
                    </button> */}
                    <div></div>
                    <button
                      onClick={() => handleModalOpen(facility, index, "basic")}
                      disabled={facilityLoading}
                    >
                      {facilityLoading && index === currentIndex
                        ? "Loading..."
                        : "View details"}
                    </button>
                  </div>
                </div>
                {/* <div className="log">
            <span>Last Activity:</span>{" "}
            {getLastActivity(facility?.plantAddress)}
          </div> */}
              </div>
            ))}
          {value === 0 &&
            facilityType === "non-green" &&
            infraBasicFacilitiesUnvalidated?.map((facility, index) => (
              <div className="validation_card">
                <div className="validation_grid">
                  <div>
                    <div>Facility Name:</div>
                    <div>
                      {facility?.organizationName && facility?.organizationName}
                    </div>
                  </div>
                  {/* <div>
            <div>Methodology:</div>
            <div>CloseCarbon</div>
          </div> */}
                  <div>
                    <div>Facility category:</div>
                    <div>Non-green</div>
                  </div>
                  <div>
                    <div>Sustainability solution:</div>
                    <div>Infrastructure change</div>
                  </div>
                </div>

                <div className="confirm">
                  <div>Validate this facility</div>

                  <div>
                    {/* <button
                      onClick={() =>
                        alert(
                          "You have to carry out a site visit in order to proceed with Validation"
                        )
                      }
                      disabled={facilityLoading}
                    >
                      Cancel
                    </button> */}
                    <div></div>
                    <button
                      onClick={() => handleModalOpen(facility, index, "basic")}
                      disabled={facilityLoading}
                    >
                      {facilityLoading && index === currentIndex
                        ? "Loading..."
                        : "View details"}
                    </button>
                  </div>
                </div>
                {/* <div className="log">
            <span>Last Activity:</span>{" "}
            {getLastActivity(facility?.plantAddress)}
          </div> */}
              </div>
            ))}
          {value === 1 &&
            facilityType === "non-green" &&
            infraGovernanceFacilitiesValidated?.map((facility, index) => (
              <div className="validation_card">
                <div className="validation_grid">
                  <div>
                    <div>Facility Name:</div>
                    <div>
                      {facility?.organizationName && facility?.organizationName}
                    </div>
                  </div>
                  {/* <div>
            <div>Methodology:</div>
            <div>CloseCarbon</div>
          </div> */}
                  <div>
                    <div>Facility category:</div>
                    <div>Non-green</div>
                  </div>
                  <div>
                    <div>Sustainability solution:</div>
                    <div>Infrastructure change</div>
                  </div>
                </div>

                <div className="confirm">
                  <div>Validate this facility</div>

                  <div>
                    {/* <button
                      onClick={() =>
                        alert(
                          "You have to carry out a site visit in order to proceed with Validation"
                        )
                      }
                      disabled={facilityLoading}
                    >
                      Cancel
                    </button> */}
                    <div></div>
                    <button
                      onClick={() => handleModalOpen(facility, index, "governance")}
                      disabled={facilityLoading}
                    >
                      {facilityLoading && index === currentIndex
                        ? "Loading..."
                        : "View details"}
                    </button>
                  </div>
                </div>
                {/* <div className="log">
            <span>Last Activity:</span>{" "}
            {getLastActivity(facility?.plantAddress)}
          </div> */}
              </div>
            ))}
          {value === 0 &&
            facilityType === "non-green" &&
            infraGovernanceFacilitiesUnvalidated?.map((facility, index) => (
              <div className="validation_card">
                <div className="validation_grid">
                  <div>
                    <div>Facility Name:</div>
                    <div>
                      {facility?.organizationName && facility?.organizationName}
                    </div>
                  </div>
                  {/* <div>
            <div>Methodology:</div>
            <div>CloseCarbon</div>
          </div> */}
                  <div>
                    <div>Facility category:</div>
                    <div>Non-green</div>
                  </div>
                  <div>
                    <div>Sustainability solution:</div>
                    <div>Infrastructure change</div>
                  </div>
                </div>

                <div className="confirm">
                  <div>Validate this facility</div>

                  <div>
                    {/* <button
                      onClick={() =>
                        alert(
                          "You have to carry out a site visit in order to proceed with Validation"
                        )
                      }
                      disabled={facilityLoading}
                    >
                      Cancel
                    </button> */}
                    <div></div>
                    <button
                      onClick={() => handleModalOpen(facility, index, "governance")}
                      disabled={facilityLoading}
                    >
                      {facilityLoading && index === currentIndex
                        ? "Loading..."
                        : "View details"}
                    </button>
                  </div>
                </div>
                {/* <div className="log">
            <span>Last Activity:</span>{" "}
            {getLastActivity(facility?.plantAddress)}
          </div> */}
              </div>
            ))}
          {value === 1 &&
            facilityType === "non-green" &&
            monitoringValidated?.map((facility, index) => (
              <div className="validation_card">
                <div className="validation_grid">
                  <div>
                    <div>Facility Name:</div>
                    <div>
                      {facility?.organizationName && facility?.organizationName}
                    </div>
                  </div>
                  {/* <div>
            <div>Methodology:</div>
            <div>CloseCarbon</div>
          </div> */}
                  <div>
                    <div>Facility category:</div>
                    <div>Non-green</div>
                  </div>
                  <div>
                    <div>Sustainability solution:</div>
                    <div>Carbon monitoring</div>
                  </div>
                </div>

                <div className="confirm">
                  <div>Validate this facility</div>

                  <div>
                    {/* <button
                      onClick={() =>
                        alert(
                          "You have to carry out a site visit in order to proceed with Validation"
                        )
                      }
                      disabled={facilityLoading}
                    >
                      Cancel
                    </button> */}
                    <div></div>
                    <button
                      onClick={() => handleModalOpen(facility, index)}
                      disabled={facilityLoading}
                    >
                      {facilityLoading && index === currentIndex
                        ? "Loading..."
                        : "View details"}
                    </button>
                  </div>
                </div>
                {/* <div className="log">
            <span>Last Activity:</span>{" "}
            {getLastActivity(facility?.plantAddress)}
          </div> */}
              </div>
            ))}
          {value === 0 &&
            facilityType === "non-green" &&
            monitoringUnvalidated?.map((facility, index) => (
              <div className="validation_card">
                <div className="validation_grid">
                  <div>
                    <div>Facility Name:</div>
                    <div>
                      {facility?.organizationName && facility?.organizationName}
                    </div>
                  </div>
                  {/* <div>
            <div>Methodology:</div>
            <div>CloseCarbon</div>
          </div> */}
                  <div>
                    <div>Facility category:</div>
                    <div>Non-green</div>
                  </div>
                  <div>
                    <div>Sustainability solution:</div>
                    <div>Carbon monitoring</div>
                  </div>
                </div>

                <div className="confirm">
                  <div>Validate this facility</div>

                  <div>
                    {/* <button
                      onClick={() =>
                        alert(
                          "You have to carry out a site visit in order to proceed with Validation"
                        )
                      }
                      disabled={facilityLoading}
                    >
                      Cancel
                    </button> */}
                    <div></div>
                    <button
                      onClick={() => handleModalOpen(facility, index)}
                      disabled={facilityLoading}
                    >
                      {facilityLoading && index === currentIndex
                        ? "Loading..."
                        : "View details"}
                    </button>
                  </div>
                </div>
                {/* <div className="log">
            <span>Last Activity:</span>{" "}
            {getLastActivity(facility?.plantAddress)}
          </div> */}
              </div>
            ))}
          {/* {monitoring?.length > 0 && (
          <div className="category">Carbon Monitoring</div>
        )} */}

          {loading && (
            <>
              <div className="category">
                <Skeleton
                  width={120}
                  height={20}
                  borderRadius={8}
                  style={{ marginBottom: "8px" }}
                />
              </div>
              <div className="validation_card">
                <div className="validation_grid">
                  <div>
                    <div>
                      <Skeleton
                        width={"64%"}
                        height={20}
                        borderRadius={8}
                        style={{ marginBottom: "8px" }}
                      />
                    </div>
                    <div>
                      <Skeleton
                        width={"100%"}
                        height={20}
                        borderRadius={8}
                        style={{ marginBottom: "8px" }}
                      />
                    </div>
                  </div>
                  <div>
                    <div>
                      <Skeleton
                        width={"64%"}
                        height={20}
                        borderRadius={8}
                        style={{ marginBottom: "8px" }}
                      />
                    </div>
                    <div>
                      <Skeleton
                        width={"100%"}
                        height={20}
                        borderRadius={8}
                        style={{ marginBottom: "8px" }}
                      />
                    </div>
                  </div>
                  <div>
                    <div>
                      <Skeleton
                        width={"64%"}
                        height={20}
                        borderRadius={8}
                        style={{ marginBottom: "8px" }}
                      />
                    </div>
                    <div>
                      <Skeleton
                        width={"100%"}
                        height={20}
                        borderRadius={8}
                        style={{ marginBottom: "8px" }}
                      />
                    </div>
                  </div>
                  {/* <div>
              <div>Methodology:</div>
              <div>CloseCarbon</div>
            </div> */}
                </div>

                <div className="log">
                  <Skeleton
                    width={"20%"}
                    height={20}
                    borderRadius={8}
                    style={{ marginBottom: "8px" }}
                  />
                  <Skeleton
                    width="60%"
                    height={20}
                    borderRadius={8}
                    style={{ marginBottom: "8px" }}
                  />
                </div>
              </div>
              <div className="category">
                <Skeleton
                  width={120}
                  height={20}
                  borderRadius={8}
                  style={{ marginBottom: "8px" }}
                />
              </div>
              <div className="validation_card">
                <div className="validation_grid">
                  <div>
                    <div>
                      <Skeleton
                        width={"64%"}
                        height={20}
                        borderRadius={8}
                        style={{ marginBottom: "8px" }}
                      />
                    </div>
                    <div>
                      <Skeleton
                        width={"100%"}
                        height={20}
                        borderRadius={8}
                        style={{ marginBottom: "8px" }}
                      />
                    </div>
                  </div>
                  <div>
                    <div>
                      <Skeleton
                        width={"64%"}
                        height={20}
                        borderRadius={8}
                        style={{ marginBottom: "8px" }}
                      />
                    </div>
                    <div>
                      <Skeleton
                        width={"100%"}
                        height={20}
                        borderRadius={8}
                        style={{ marginBottom: "8px" }}
                      />
                    </div>
                  </div>
                  <div>
                    <div>
                      <Skeleton
                        width={"64%"}
                        height={20}
                        borderRadius={8}
                        style={{ marginBottom: "8px" }}
                      />
                    </div>
                    <div>
                      <Skeleton
                        width={"100%"}
                        height={20}
                        borderRadius={8}
                        style={{ marginBottom: "8px" }}
                      />
                    </div>
                  </div>
                  <div>
                    <div>
                      <Skeleton
                        width={"64%"}
                        height={20}
                        borderRadius={8}
                        style={{ marginBottom: "8px" }}
                      />
                    </div>
                    <div>
                      <Skeleton
                        width={"100%"}
                        height={20}
                        borderRadius={8}
                        style={{ marginBottom: "8px" }}
                      />
                    </div>
                  </div>
                  <div>
                    <div>
                      <Skeleton
                        width={"64%"}
                        height={20}
                        borderRadius={8}
                        style={{ marginBottom: "8px" }}
                      />
                    </div>
                    <div>
                      <Skeleton
                        width={"100%"}
                        height={20}
                        borderRadius={8}
                        style={{ marginBottom: "8px" }}
                      />
                    </div>
                  </div>
                  {/* <div>
              <div>Methodology:</div>
              <div>CloseCarbon</div>
            </div> */}
                </div>

                <div className="log">
                  <Skeleton
                    width={"20%"}
                    height={20}
                    borderRadius={8}
                    style={{ marginBottom: "8px" }}
                  />
                  <Skeleton
                    width="60%"
                    height={20}
                    borderRadius={8}
                    style={{ marginBottom: "8px" }}
                  />
                </div>
              </div>
            </>
          )}
        </div>

        {modalOpen && (
          <div className="modal" onClick={handleModalClose}>
            <div className="modal_inner">
              <div className="title">{selectedFacility?.organizationName}</div>
              {/* <div className="subtitle">
                Plot 42B Fola agoro, Satelite Ogudu, Lagos Nigeria
              </div> */}

              <div className="modal_grid">
                <div className="grid_item">
                  <div className="label">
                    {selectedFacility?.scenario === "green"
                      ? "Green project description"
                      : "Facility/Project description"}
                  </div>
                  <div className="document">
                    {selectedFacility?.scenario === "green" ? selectedKyc?.greenProjectDescription : selectedKyc?.facilityDescription}
                  </div>
                </div>
                <div className="grid_item">
                  <div className="label">
                    {selectedFacility?.scenario === "green"
                      ? "Green project location"
                      : "Facility/Project location"}
                  </div>
                  <div className="document">
                    {selectedFacility?.scenario === "green" ? selectedKyc?.greenProjectLocation : selectedKyc?.facilityLocation}
                  </div>
                </div>
                {selectedFacility?.scenario !== "carbon-monitoring" && <div className="grid_item">
                  <div className="label">
                    {selectedFacility?.scenario === "green"
                      ? "Green project/ Renewable energy"
                      : "Facility/Project fuel source"}
                  </div>
                  <div className="document">
                    {selectedFacility?.scenario === "green"
                      ? selectedKyc?.renewableEnergySolutions
                      : selectedKyc?.projectFuelSource}
                  </div>
                </div>}
              {selectedFacility?.scenario !== "carbon-monitoring" &&  <div className="grid_item">
                  <div className="label">Baseline scenario</div>
                  <div className="document">
                    {selectedKyc?.baseLineScenario}
                  </div>
                </div>}
                {selectedFacility?.scenario !== "carbon-monitoring" &&<div className="grid_item">
                  <div className="label">
                    {selectedFacility?.scenario === "green"
                      ? "Energy source in baseline scenario"
                      : "Fuel source in baseline scenario"}
                  </div>
                  <div className="document">
                    {selectedFacility?.scenario === "green"
                      ? selectedKyc?.baseLineEnergySource
                      : selectedKyc?.baseLineFuelSource}
                  </div>
                </div>}
                <div className="grid_item pointer">
                  <div className="label">Quantification Methodology</div>
                  <div
                    className="document"
                    onClick={() => window.open(`https://${selectedKyc?.quantificationMethodology}`)}
                  >
                    <div>Uploaded Document</div>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.5 1.5C6.70435 1.5 5.94129 1.81607 5.37868 2.37868C4.81607 2.94129 4.5 3.70435 4.5 4.5V19.5C4.5 20.2956 4.81607 21.0587 5.37868 21.6213C5.94129 22.1839 6.70435 22.5 7.5 22.5H16.5C17.2956 22.5 18.0587 22.1839 18.6213 21.6213C19.1839 21.0587 19.5 20.2956 19.5 19.5V8.121C19.4995 7.52446 19.2621 6.95255 18.84 6.531L14.4705 2.1585C14.2615 1.9496 14.0133 1.78393 13.7403 1.67094C13.4672 1.55795 13.1745 1.49987 12.879 1.5H7.5ZM6 4.5C6 4.10218 6.15804 3.72064 6.43934 3.43934C6.72064 3.15804 7.10218 3 7.5 3H12V6.75C12 7.34674 12.2371 7.91903 12.659 8.34099C13.081 8.76295 13.6533 9 14.25 9H18V19.5C18 19.8978 17.842 20.2794 17.5607 20.5607C17.2794 20.842 16.8978 21 16.5 21H7.5C7.10218 21 6.72064 20.842 6.43934 20.5607C6.15804 20.2794 6 19.8978 6 19.5V4.5ZM17.6895 7.5H14.25C14.0511 7.5 13.8603 7.42098 13.7197 7.28033C13.579 7.13968 13.5 6.94891 13.5 6.75V3.3105L17.6895 7.5Z"
                        fill="#667185"
                      />
                    </svg>
                  </div>
                </div>
                {selectedFacility?.scenario !== "carbon-monitoring" &&<div className="grid_item">
                  <div className="label">
                    Additional information relevant to the project
                  </div>
                  <div className="document">
                    {selectedKyc?.additionalInfomation}
                  </div>
                </div>}
              </div>

              <div className="button_group">
                <button onClick={() => setModalOpen2(true)}>Reject</button>
                <button
                  onClick={() => handleValidate(selectedFacility)}
                  disabled={validateLoading}
                >
                  {validateLoading ? "Loading..." : "Validate"}
                </button>
              </div>
            </div>
          </div>
        )}

        {modalOpen2 && (
          <div
            className="reject-modals reject-modal"
            onClick={handleModalClose2}
          >
            <div className="reject-modal__inner">
              <div className="reject-modal__inner__title">
                Reasons for rejecting this facility
              </div>
              <div className="reject-modal__inner__subtitle">
                Give reasons as to why this facility is been rejected:
              </div>

              <select
                className="reject-modal__inner__field"
                name=""
                id=""
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              >
                <option value="">Select reason</option>
                <option value="Project fails to meet additionality requirements. This means that the project does not contribute any genuine additional value or positive impact beyond what would have happened under normal circumstances.">
                Project fails to meet additionality requirements
                </option>
                <option value="Project is not within CloseCarbon scope. This means that the project does not fall within the scope of CloseCarbon’s activities, such as an afforestation or aviation project.">
                Project is not within CloseCarbon scope
                </option>
                <option value="Not enough information in KYC (Know Your Customer). This means that there is insufficient information provided to understand the objectives of the project, description of the technologies and conditions existing prior to project initiation.">
                Not enough information in KYC (Know Your Customer)
                </option>
                <option value="Others. This category includes any other reasons not covered by the above points. To be filled in a text box.">
                  Others
                </option>
              </select>

              {/* <div className="reject-modal__inner__flex">
              <input
                type="checkbox"
                name=""
                id=""
                checked={reason === "Material misstatements"}
                onChange={(e) => {
                  if (e.target.checked) {
                    setReason("Material misstatements");
                  }else {
                    setReason("");
                  }
                }}
              />
              Material misstatements
            </div>
            <div className="reject-modal__inner__flex">
              <input
                type="checkbox"
                name=""
                id=""
                checked={reason === "Incomplete or inaccurate data"}
                onChange={(e) => {
                  if (e.target.checked) {
                    setReason("Incomplete or inaccurate data");
                  }else {
                    setReason("");
                  }
                }}
              />
              Incomplete or inaccurate data
            </div>
            <div className="reject-modal__inner__flex">
              <input
                type="checkbox"
                name=""
                id=""
                checked={reason === "Incorrect methodology"}
                onChange={(e) => {
                  if (e.target.checked) {
                    setReason("Incorrect methodology");
                  }else {
                    setReason("");
                  }
                }}
              />
              Incorrect methodology
            </div>
            <div className="reject-modal__inner__flex">
              <input
                type="checkbox"
                name=""
                id=""
                checked={reason === "Incorrect emission factor"}
                onChange={(e) => {
                  if (e.target.checked) {
                    setReason("Incorrect emission factor");
                  }else {
                    setReason("");
                  }
                }}
              />
              Incorrect emission factor
            </div> */}
              {reason.includes("Others. This category includes any other reasons not covered by the above points. To be filled in a text box.") && <ReactQuill
                theme="snow"
                placeholder="Tell us more(optional)"
                value={content}
                onChange={setContent}
                modules={quilModules}
                formats={quilFormats}
                style={{
                  height: "235px",
                  marginBottom: "36px",
                  marginTop: "12px",
                }}
              />}

              <button
                className="reject-modal__inner__button"
                disabled={rejectLoading}
                onClick={handleReject}
              >
                {rejectLoading ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Validation;
